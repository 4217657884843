import React from 'react';
import Theme, { nib } from '@nib-components/theme';
import { SecondaryButton } from '@nib-components/button';
import ErrorTemplate from '@nib-components/error-template';

const Error404Page = () => {
  return (
    <Theme theme={nib}>
      <ErrorTemplate
        title="[404]"
        description="It looks like the page you're looking for cannot be found. Perhaps you could try one of these alternatives."
        variation="nz"
      >
        <SecondaryButton color="dark" component="a" href="/">
          Back to homepage
        </SecondaryButton>
      </ErrorTemplate>
    </Theme>
  );
};

export default Error404Page;
